/*!

=========================================================
* VeraciTek ReactJS & NODE Template - v2019
=========================================================

* Product Page: https://www.veracitek.com/?info=vtekForkDashboard
* Copyright 2019 VeraciTek, Inc. (https://www.veracitek.com)
* FORK for IU 2019

* Coded by VeraciTek, Inc.

=========================================================

* KOD

*/
import {
  primaryColor,
  blackColor,
  hexToRgb
} from "../../../assets/jss/vtekForkDashboard-f-react.jsx";

const checkboxAdnRadioStyle = {
  root: {
    padding: "13px",
    "&:hover": {
      backgroundColor: "unset"
    }
  },
  labelRoot: {
    marginLeft: "-14px"
  },
  checked: {
    color: primaryColor[0] + "!important"
  },
  checkedIcon: {
    width: "20px",
    height: "20px",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
    borderRadius: "3px"
  },
  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "10px",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
    borderRadius: "3px"
  },
  radio: {
    color: primaryColor[0] + "!important"
  },
  radioChecked: {
    width: "20px",
    height: "20px",
    border: "1px solid " + primaryColor[0],
    borderRadius: "50%"
  },
  radioUnchecked: {
    width: "0px",
    height: "0px",
    padding: "10px",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
    borderRadius: "50%"
  }
};

export default checkboxAdnRadioStyle;
