import React from 'react';
//import logo from './logo.svg';
import { Router, Route, Switch, Redirect } from "react-router-dom";
 
// core components
import "./assets/css/vtekForkDashboard-f-react.css";
import LEFTBAR from "./layouts/LEFTBAR.jsx";
import TOPBAR from "./layouts/TOPBAR.jsx";


import { createBrowserHistory } from "history";
import { PrivateRoute } from './_components';
import DIYRecord from "./views/DIYRecord/DIYRecord.jsx";
import { LoginPage } from './views/LoginPage';

const hist = createBrowserHistory();

function App() {
  return (
    <Router history={hist}>
    <Switch>            
      <Route path="/login" component={LoginPage} />            
      <PrivateRoute path="/" component={LEFTBAR} />         
      <Redirect exact from="/" to="/diyRecord" />
    </Switch>
  </Router>
  );
  /*
  return (
    <div className="jumbotron">
        <div className="container">
            <div className="col-sm-8 col-sm-offset-2">
                <Router>
                    <div>
                        <PrivateRoute exact path="/" component={DIYRecord} />
                        <Route path="/login" component={LoginPage} />
                    </div>
                </Router>
            </div>
        </div>
    </div>
);*/
}

export default App;
