/*!

=========================================================
* VeraciTek ReactJS & NODE Template - v2019
=========================================================

* Product Page: https://www.veracitek.com/?info=vtekForkDashboard
* Copyright 2019 VeraciTek, Inc. (https://www.veracitek.com)
* FORK for IU 2019

* Coded by VeraciTek, Inc.

=========================================================

* KOD

*/
import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  grayColor
} from "../../../../assets/jss/vtekForkDashboard-f-react.jsx";

const cardIconStyle = {
  cardIcon: {
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader": {
      borderRadius: "3px",
      backgroundColor: grayColor[0],
      padding: "15px",
      marginTop: "-20px",
      marginRight: "15px",
      float: "left"
    }
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader
};

export default cardIconStyle;
