/*!

=========================================================
* VeraciTek ReactJS & NODE Template - v2019
=========================================================

* Product Page: https://www.veracitek.com/?info=vtekForkDashboard
* Copyright 2019 VeraciTek, Inc. (https://www.veracitek.com)
* FORK for IU 2019

* Coded by VeraciTek, Inc.

=========================================================

* KOD 

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import VolumeDown from '@material-ui/icons/VolumeDown';
import AccountCircle from '@material-ui/icons/AccountCircle';
// core components/views for Admin layout
import DashboardPage from "./views/Dashboard/Dashboard.jsx";
import UserProfile from "./views/UserProfile/UserProfile.jsx";
import TableList from "./views/TableList/TableList.jsx";
import Typography from "./views/Typography/Typography.jsx";
import Icons from "./views/Icons/Icons.jsx";
import DIYRecord from "./views/DIYRecord/DIYRecord.jsx";
import DefaultInternalPage from "./views/DefaultInternalPage/DefaultInternalPage.jsx";
import MyRecordings from "./views/MyRecordings/MyRecordings.jsx";
import ViewStreams from "./views/ViewStreams/ViewStreams.jsx";
import Maps from "./views/Maps/Maps.jsx";
import NotificationsPage from "./views/Notifications/Notifications.jsx";
// core components/views for RTL layout
import RTLPage from "./views/RTLPage/RTLPage.jsx";

const dashboardRoutes = [ 
  {
    path: "/diyRecord",
    name: "Record Me",
    inSideBar: true,
    inTopBar: true,
//    rtlName: "الرموز",
    icon: Language,
    component: DIYRecord,
    layout: "/LEFTBAR"
  }, 
  {
    path: "/myRecordings",
    name: "My Recordings",
    inSideBar: true,
    inTopBar: true,
//  rtlName: "پشتیبانی از راست به چپ",
    icon: VolumeDown,
    component: MyRecordings,
    layout: "/LEFTBAR"
  },   /*
  {
    path: "/viewStreams",
    name: "All Streams",
    inSideBar: true,
    inTopBar: true,
//  rtlName: "پشتیبانی از راست به چپ",
    icon: SupervisorAccount,
    component: ViewStreams,
    layout: "/LEFTBAR"
  }, */
  {
    path: "/user",
    name: "My Account",
    inSideBar: true,
    inTopBar: true,
//  rtlName: "ملف تعريفي للمستخدم",
    icon: AccountCircle,
    component: UserProfile,
    layout: "/LEFTBAR"
  },
  /*
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },  
  {
    path: "/user",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin"
  },    
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin"
  },    
  {
    path: "/template",
    name: "Template",
    rtlName: "إخطارات",
    icon: LocationOn,
    component: DefaultInternalPage,
    layout: "/admin"
  },  */
];

export default dashboardRoutes;

/*
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/user",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin"
  },    
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin"
  } */