/*!

=========================================================
* VeraciTek ReactJS & NODE Template - v2019
=========================================================

* Product Page: https://www.veracitek.com/?info=vtekForkDashboard
* Copyright 2019 VeraciTek, Inc. (https://www.veracitek.com)
* FORK for IU 2019

* Coded by VeraciTek, Inc.

=========================================================

* KOD

*/
/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Card from "../../components/Card/Card.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import md5 from 'md5';

import iconsStyle from "../../assets/jss/vtekForkDashboard-f-react/views/iconsStyle.jsx";

function DIYRecord(props) {
  const { classes } = props;
  const user = JSON.parse(localStorage.getItem('user'));
  // console.log(JSON.stringify(user));
   
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card plain>
          <CardHeader plain color="primary">
            <h4 className={classes.cardTitleWhite}>Begin Recording...</h4>
            <p className={classes.cardCategoryWhite}>
            In cooperation with our friends from{" "}
              <a
                href="http://www.veracitek.com"
                target="_blank"
              >
                VeraciTek.com
              </a>
            </p>
          </CardHeader>
          <CardBody>
            <GridItem xs={12} sm={12} md={6}> 
              <iframe
                allow="microphone; autoplay"
                className={classes.iframe}
                src={"https://diy.recordedword.com/api/record.vTek?autostart=0&sid="+user.id+"&a="+md5(user.authdata)}
                title="DIYRecord iframe"
              >
                <p>Your browser does not support iframes.</p>
              </iframe>
              </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

DIYRecord.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(iconsStyle)(DIYRecord);
