/*!

VTEK 

*/
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "../components/Navbars/Navbar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import Topbar from "../components/Topbar/Topbar.jsx";
//import FixedPlugin from "../components/FixedPlugin/FixedPlugin.jsx";

import routes from "../routes.js";

import dashboardStyle from "../assets/jss/vtekForkDashboard-f-react/layouts/dashboardStyle.jsx";

import image from "../assets/img/Topbar-2.jpg";
import logo from "../assets/img/reactlogo.png";
import { PrivateRoute } from '../_components';


let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      //alert(prop.layout);
      if (prop.layout === "/TOPBAR") {
        return (
          //<PrivateRoute exact path={prop.layout + prop.path} component={prop.component} key={key} />            //RAJ10072019 seems to be a pretty lame hack to force the use of the layout in the url, which I'm getting rid of.
          <PrivateRoute exact path={prop.path} component={prop.component} key={key} />      
        );
      }
      return null;
    })}
    <Redirect from="/" to="/diyRecord" />
  </Switch>
);

class Dashboard extends React.Component {
  state = {
    user: {},    
    image: image,
    color: "blue",
    hasImage: true,
    fixedClasses: "dropdown show",
    mobileOpen: false
  };
  
  componentDidMount() {
    this.setState({ 
        user: JSON.parse(localStorage.getItem('user'))
    });    
 }

  mainPanel = React.createRef();
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    //alert(window.location.pathname);
    return window.location.pathname !== "/ATTN/maps";
  }
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.mainPanel.current.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }e
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  render() { 
    const { classes, ...rest } = this.props;
    return (
      <div className={classes.wrapper}>
        <div  className={classes.mainPanel_topNav} ref={this.mainPanel}>
        <Topbar
          routes={routes}
          logoText={" "}
          logo="/public/favicon.ico"
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          {...rest}
        />  
        {/*
          <Navbar
            routes={routes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
        /> */}
          
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}
          {this.getRoute() ? <Footer /> : null
        /*
        <FixedPlugin
            handleImageClick={this.handleImageClick}
            handleColorClick={this.handleColorClick}
            bgColor={this.state["color"]}
            bgImage={this.state["image"]}
            handleFixedClick={this.handleFixedClick}
            fixedClasses={this.state.fixedClasses}
          />*/
        }                    
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
