/*!

=========================================================
* VeraciTek ReactJS & NODE Template - v2019
=========================================================

* Product Page: https://www.veracitek.com/?info=vtekForkDashboard
* Copyright 2019 VeraciTek, Inc. (https://www.veracitek.com)
* FORK for IU 2019

* Coded by VeraciTek, Inc.

=========================================================

* KOD

*/
// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

var bugs = [
  'Complete Blockchain Podcast',
  "Complete Salesmanship 101 Stream",
  "Go live with Crypto2019",
  "R&R"
];
var website = [
  "Complete blog entry for RW.com.",
  'SEO'
];
var server = [
  "Warning: You're running out of archive space. Older files will be overwritten.",
  "Error: Your credentials for Zoom have expired & are no longer working.",
  'Warning: 12 users experienced connection problems during the last stream.'
];

module.exports = {
  // these 3 are used to create the tasks lists in TasksCard - Dashboard view
  bugs,
  website,
  server
};
