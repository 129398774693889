/*!

VTEK

*/
/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Link } from 'react-router-dom';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from '@material-ui/core/styles';
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Language from "@material-ui/icons/Language";
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import VolumeDown from '@material-ui/icons/VolumeDown';
import AccountCircle from '@material-ui/icons/AccountCircle';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";
import RTLNavbarLinks from "../Navbars/RTLNavbarLinks.jsx";

import TopbarStyle from "../../assets/jss/vtekForkDashboard-f-react/components/TopbarStyle.jsx";

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    '.MuiBottomNavigationAction-label': {
      fontSize: '1em',
    },
    '.MuiBottomNavigationAction-label.Mui-selected': {
      fontSize: '1.1em',            
    },
    '.Mui-selected': {
      backgroundColor: "#39CDE5",      
    },    
    '.MuiBottomNavigation-root': {
      width: '100%',
      position: 'fixed',
      backgroundColor: "#6E6E6E",
      bottom: 0,
    }
  },
})(() => null);


const Topbar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
    const [value, setValue] = React.useState('recents');  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

  const { classes, color, logo, image, logoText, routes } = props;

  var links = (
    <div className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.inTopBar)
        {                 
        var listItemClasses;
        if (prop.path === "/veraciTek") {          
          listItemClasses = classNames({
            [" " + classes[color]]: true
          });
        } else {
          listItemClasses = classNames({
            //[" " + classes[color]]: activeRoute(prop.layout + prop.path) //RAJ10072019 seems to be a pretty lame hack to force the use of the layout in the url, which I'm getting rid of.
            [" " + classes[color]]: activeRoute( prop.path)
          });
        }
        const whiteFontClasses = classNames({
          //[" " + classes.whiteFont]: activeRoute(prop.layout + prop.path) //RAJ10072019 seems to be a pretty lame hack to force the use of the layout in the url, which I'm getting rid of.
          [" " + classes.whiteFont]: activeRoute(prop.path) 
        });    
        return (
          <NavLink
            //to={prop.layout + prop.path}//RAJ10072019 seems to be a pretty lame hack to force the use of the layout in the url, which I'm getting rid of.
            to={prop.path}
            className={classes.item}
            activeClassName="active"
            key={key}
          > 
            <span>
              {typeof prop.icon === "string" ? (
                <Icon  fontSize="large"
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                />
              )}
             {props.rtlActive ? prop.rtlName : prop.name}{'\u00A0'}{'\u00A0'}</span>           
         </NavLink> 
        );
}})}
    </div>
  );




  var bottomLinks =      
      routes.map((prop, key) => {
        if (prop.inTopBar)
        {                 
        var listItemClasses;
        if (prop.path === "/veraciTek") {          
          listItemClasses = classNames({
            [" " + classes[color]]: true
          });
        } else {
          listItemClasses = classNames({
            //[" " + classes[color]]: activeRoute(prop.layout + prop.path) //RAJ10072019 seems to be a pretty lame hack to force the use of the layout in the url, which I'm getting rid of.
            [" " + classes[color]]: activeRoute( prop.path)
          });
        }
        const whiteFontClasses = classNames({
          //[" " + classes.whiteFont]: activeRoute(prop.layout + prop.path) //RAJ10072019 seems to be a pretty lame hack to force the use of the layout in the url, which I'm getting rid of.
          [" " + classes.whiteFont]: activeRoute(prop.path) 
        }); 
        if (prop.name=="Record Me")  
          return (
            <BottomNavigationAction  icon={<Language fontSize="large" />} component={Link} to={prop.path} key={key} className={classes.item} label={prop.name} value={prop.name}  />         
          );
        else 
        if (prop.name=="My Streams")  
         return (
          <BottomNavigationAction  icon={<VolumeDown  fontSize="large" />} component={Link} to={prop.path} key={key} className={classes.item} label={prop.name} value={prop.name}  />         
        );
        else 
        if (prop.name=="All Streams")  
         return (
          <BottomNavigationAction  icon={<SupervisorAccount  fontSize="large" />} component={Link} to={prop.path} key={key} className={classes.item} label={prop.name} value={prop.name}  />         
        );
        else 
        if (prop.name=="My Account")  
         return (
          <BottomNavigationAction  icon={<AccountCircle  fontSize="large" />} component={Link} to={prop.path} key={key} className={classes.item} label={prop.name} value={prop.name}  />         
        );
}});

  var brand = (
    <div className={classes.logo}>      
        <span className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </span>
        {logoText}      
    </div>
  );


  return (    
    <div>
      <Hidden   mdUp implementation="css">        
      
        <Drawer
          variant="permanent"
          anchor={props.rtlActive ? "right" : "left"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          
          <div className={classes.topbarWrapper}>         
          {brand}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
          </Drawer> 
          <GlobalCss />
          <BottomNavigation value={value}
           onChange={(event, newValue) => {
            setValue(newValue);
            }}
      showLabels
      className={classes.bottomNav}>
            {bottomLinks}
          </BottomNavigation>
      </Hidden>
      <Hidden smDown implementation="css">       
      
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"          
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >          
          <div className={classes.topbarWrapper}>
          <div style={{"float":"right","width":"130px"}}>                
          {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}        
          </div>      
          {brand}{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
          </Drawer> 
          
      </Hidden>
    </div>
  );
};

Topbar.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};



export default withStyles(TopbarStyle)(Topbar);
